import axios from "axios";
import React, { useState } from "react";
import GetAuth from "../../GetAuth";

const useDataByEmail = () => {
	const [data, setData] = useState();
	const { user, token } = GetAuth();
	React.useEffect(() => {
		axios
			.get(
				`https://dbc.bcard.a2hosted.com/userbyemail/${user?.email}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res) => {
				setData(res.data);
			})
			.catch((error) => {
				setData({ email: "Not Found" });
			});
	}, [token, user?.email]);
	return [data];
};

export default useDataByEmail;
