import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	Table,
	Paper,
	Grid,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TextField,
	Box,
	Container,
	Backdrop,
	CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useForm } from "react-hook-form";
import GetAuth from "../../../GetAuth";

const AddVideo = () => {
	const [id, setId] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	let count = 1;
	const [videos, setVideos] = useState();
	const { user, token } = GetAuth();
	React.useEffect(() => {
		axios
			.get(
				`https://dbc.bcard.a2hosted.com/userbyemail/${user?.email}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res) => {
				setId(res.data?._id);
				setVideos(res.data?.videos);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [token, user?.email, submitting, deleted]);
	const onSubmit = ({ videoTitle, videoLink }) => {
		setSubmitting(true);
		const data = {
			videoTitle,
			videoLink,
			submitTime: new Date().toLocaleString(),
			videoId: `video${Math.floor(100000 + Math.random() * 900000)}`,
		};
		axios
			.put(`https://dbc.bcard.a2hosted.com/videos/${id}`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Added",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleDelete = (videoId) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				axios
					.put(
						`https://dbc.bcard.a2hosted.com/deleteVideos/${id}/${videoId}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That video has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
						setDeleted(false);
					});
			}
		});
	};
	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold", mb: 1 }}
				variant='h4'>
				Post New Video
			</Typography>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				sx={{ mx: "auto" }}>
				<form onSubmit={handleSubmit(onSubmit)} method='post'>
					<Grid container spacing={2}>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='videoLink'
								label='Video Link'
								{...register("videoLink", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='videoTitle'
								label='Video Title'
								{...register("videoTitle", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Button
								className='sendButton'
								type='submit'
								variant='contained'
								sx={{
									width: "100%",
									mb: 2,
									px: 3,
									fontWeight: "bold",
									borderRadius: "25px",
								}}>
								Post
							</Button>
						</Grid>
					</Grid>
				</form>
				<Paper
					className='container'
					sx={{
						overflow: "auto",
						maxHeight: "85vh",
						maxWidth: "90vw",
						m: 2,
						width: "100%",
					}}>
					<Table size='small' stickyHeader aria-label='sticky table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='center' colSpan={4}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h4'>
										All Videos
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Title</TableCell>
								<TableCell align='left'>Action</TableCell>
							</TableRow>
						</TableHead>
						{videos?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{videos?.map((video) => (
									<TableRow
										key={video?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>{video?.videoTitle}</TableCell>
										<TableCell align='left' className='actionButton'>
											<Button
												onClick={() => handleDelete(video?.videoId)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!videos || deleted || submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default AddVideo;
