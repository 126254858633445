import {
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	NativeSelect,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box } from "@mui/system";

const AllUsers = () => {
	const [deleted, setDeleted] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [users, setUsers] = useState();
	useEffect(() => {
		fetch(`https://dbc.bcard.a2hosted.com/allusers`)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data);
			});
	}, [deleted, submitting]);
	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://dbc.bcard.a2hosted.com/deleteUser/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That user has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const handleStatus = (id, status) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: `Yes, ${status}  it!`,
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(`https://dbc.bcard.a2hosted.com/updateStatus/${id}`, {
						status: status,
					})
					.then(function (response) {
						setDeleted(false);
						Swal.fire({
							icon: "success",
							title: "Successfully Updated",
							showConfirmButton: false,
							timer: 1500,
						});
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	const [search, setSearch] = React.useState("");
	const handleChange = (event) => {
		setSearch(event.target.value);
	};
	const [newList, setNewList] = React.useState([]);
	useEffect(() => {
		const searchs = users
			?.reverse()
			.filter(
				(user) =>
					user?.name?.toLowerCase().includes(search.toLowerCase()) ||
					user?.email?.toLowerCase().includes(search.toLowerCase()) ||
					user?.phone?.toLowerCase().includes(search.toLowerCase()) ||
					user?.url?.toLowerCase().includes(search.toLowerCase()),
			);
		setNewList(searchs);
	}, [search, users]);

	const [perpage, setPerpage] = React.useState(10);
	const [newArray, setNewArray] = React.useState();
	const [page, setPage] = React.useState(1);
	const handleChanges = (event, value) => {
		setPage(value);
	};
	useEffect(() => {
		setNewArray(newList?.slice((page - 1) * perpage, page * perpage));
	}, [newList, page, perpage]);

	return (
		<>
			<Box sx={{ height: "155px", mx: 2 }}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h4'>
					All Users
				</Typography>
				<Typography className='textColor'>
					Last Added User in {users?.[users?.length - 1]?.joiningTime || "N/A"}
				</Typography>
				<TextField
					size='small'
					sx={{ width: "100%", maxWidth: "400px" }}
					id='outlined-name'
					placeholder='Search by Name / Email / Phone / Username'
					value={search}
					onChange={handleChange}
				/>
				<Box
					className='pagination'
					sx={{ display: "flex", justifyContent: "center", my: 1 }}>
					<FormControl>
						<NativeSelect
							defaultValue={perpage}
							onChange={(e) => setPerpage(e.target.value)}>
							<option value={10}>10</option>
							<option value={15}>15</option>
							<option value={20}>20</option>
							<option value={40}>40</option>
							<option value={50}>50</option>
						</NativeSelect>
					</FormControl>
					<Pagination
						sx={{
							display: "flex",
							justifyContent: "center !important",
						}}
						count={Math.ceil(newList?.length / perpage)}
						page={page}
						onChange={handleChanges}
						variant='outlined'
						shape='rounded'
					/>
				</Box>
			</Box>
			<Paper
				className='container'
				sx={{
					overflow: "auto",
					maxHeight: "calc(90vh - 155px)",
					maxWidth: "90vw",
					mx: 2,
				}}>
				<Table size='small'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Name</TableCell>
							<TableCell align='left'>Email</TableCell>
							<TableCell align='left'>Role</TableCell>
							<TableCell align='left'>Joining Time</TableCell>
							<TableCell align='left'>Recureted By</TableCell>
							<TableCell align='center'>Action</TableCell>
						</TableRow>
					</TableHead>
					{users?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{newArray?.map((user, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{user?.headline?.title}</TableCell>
									<TableCell align='left'>{user?.email}</TableCell>
									<TableCell align='left'>
										<NativeSelect
											onChange={(e) => {
												Swal.fire({
													title: "Are you sure?",
													icon: "warning",
													showCancelButton: true,
													confirmButtonColor: "#3085d6",
													cancelButtonColor: "#d33",
													confirmButtonText: `Yes, Change Role!`,
												}).then((result) => {
													if (result.isConfirmed) {
														axios
															.put(
																`https://dbc.bcard.a2hosted.com/rolechange/${user?._id}`,
																{
																	role: e.target.value,
																},
															)
															.then(function (response) {
																setSubmitting(false);
																Swal.fire({
																	icon: "success",
																	title: "Successfully Updated",
																	showConfirmButton: false,
																	timer: 1500,
																});
															})
															.catch(function (error) {
																console.log(error);
															});
													} else {
														setSubmitting(false);
													}
												});
											}}
											sx={{ minWidth: "95px" }}
											defaultValue={user?.role}>
											<option value={"Admin"}>Admin</option>
											<option value={"Agent"}>Agent</option>
										</NativeSelect>
									</TableCell>

									<TableCell align='left'>{user?.joiningTime}</TableCell>
									<TableCell align='left'>
										{user?.recuretedBy || "N/A"}
									</TableCell>

									<TableCell align='center'>
										<Box sx={{ display: "flex" }} className='online'>
											{user?.status === "Active" && (
												<Button
													onClick={() =>
														window.open(`/@${user?.url}`, "_blank").focus()
													}
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<LanguageIcon />
												</Button>
											)}
											{user?.role === "Admin" ? (
												<Button
													disabled
													className=' border'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "gray !important",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											) : (
												<Button
													className='button border'
													onClick={() => handleDelete(user?._id)}
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											)}
											{user?.role === "Admin" ? (
												<Button
													disabled
													className=' border'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "gray !important",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<CloseIcon />
												</Button>
											) : (
												<>
													{user?.status === "Inactive" ? (
														<Button
															className='button border'
															onClick={() => handleStatus(user?._id, "Active")}
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "green !important",
																borderRadius: "25px",
																m: 0.5,
															}}
															variant='contained'>
															<DoneIcon />
														</Button>
													) : (
														<Button
															className='button border'
															onClick={() =>
																handleStatus(user?._id, "Inactive")
															}
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "#ef1a1a !important",
																borderRadius: "25px",
																m: 0.5,
															}}
															variant='contained'>
															<CloseIcon />
														</Button>
													)}
												</>
											)}
										</Box>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</>
	);
};

export default AllUsers;
