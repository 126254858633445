import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	Divider,
	Box,
	CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HouseIcon from "@mui/icons-material/House";
import { reactLocalStorage } from "reactjs-localstorage";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { styled } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const AppartmentType = ({ page, setPage, rentForEdit }) => {
	const Input = styled("input")({
		display: "none",
	});
	const propertyType = [
		{
			type: "Condo",
			types: [{ type: "Condo" }],
		},
		{
			type: "Appartment",
			types: [
				{ type: "Appartment" },
				{ type: "Studio" },
				{ type: "Bachelor" },
				{ type: "Basement" },
				{ type: "Duplex" },
				{ type: "Loft" },
			],
		},
		{
			type: "House",
			types: [
				{ type: "House" },
				{ type: "Town House" },
				{ type: "Multi Unit" },
				{ type: "Cabin" },
				{ type: "Cottage" },
			],
		},
		{
			type: "Room",
			types: [{ type: "Private Room" }, { type: "Shared Room" }],
		},
	];
	useEffect(() => {
		setUnit(rentForEdit?.unit);
		setAddress(rentForEdit?.address);
		setType(
			propertyType?.findIndex((d) => d?.type === rentForEdit?.propertyType) ||
				0,
		);
		setSelcetType(
			propertyType
				?.find((d) => d?.type === rentForEdit?.propertyType)
				?.types?.findIndex((d) => d?.type === rentForEdit?.selectType) || 0,
		);
		setImages(
			rentForEdit?.images?.length
				? rentForEdit?.images
				: reactLocalStorage.getObject("images"),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rentForEdit]);
	const [unit, setUnit] = useState();
	const [address, setAddress] = useState();
	const [type, setType] = useState();
	const [selectType, setSelcetType] = useState();
	const data = {
		propertyType: propertyType?.[type]?.type,
		selectType: propertyType?.[type]?.types?.[selectType]?.type,
		address,
		unit,
	};

	const nextStep = () => {
		reactLocalStorage.setObject("dataPage1", { data });
	};
	const [images, setImages] = useState();
	const [loading, setLoading] = useState(false);
	const [insertImage, setInsertImage] = useState();
	const [preview, setPreview] = useState();

	const uploadImage = async (insertImage) => {
		const data = new FormData();
		data.append("file", insertImage);
		data.append("upload_preset", "RentPhotos");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/century-21/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImages([...images, file.secure_url]);
		setLoading(false);
	};
	useEffect(() => {
		reactLocalStorage.setObject("images", images);
		setInsertImage(null);
	}, [images]);
	const [enable, setEnable] = useState(false);
	useEffect(() => {
		const images = reactLocalStorage.getObject("images");
		if (images?.length > 0) {
			setEnable(true);
		} else {
			setEnable(false);
		}
	}, [images]);
	useEffect(() => {
		if (!insertImage) {
			setPreview(undefined);
			return;
		}
		const objectUrl = URL.createObjectURL(insertImage);
		setPreview(objectUrl);
		return () => URL.revokeObjectURL(objectUrl);
	}, [insertImage]);

	return (
		<Container sx={{ pt: 5, pb: 2, textAlign: "left" }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				Property Type
			</Typography>
			<Typography
				className='textColor'
				variant='h6'
				component='div'
				gutterBottom>
				Select the category that best matches your property
			</Typography>
			<Grid container spacing={2}>
				{propertyType?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12} sx={{ mx: "auto" }}>
						<Button
							onClick={() => setType(i)}
							className={type === i && "activeButton"}
							variant='contained'
							sx={{
								width: "100%",
								p: 3,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							<HouseIcon sx={{ mr: 0.7 }} /> {p?.type}
						</Button>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<LocationOnIcon sx={{ mr: 0.5 }} /> House Type
			</Typography>
			<Grid container spacing={2}>
				{propertyType?.[type]?.types?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12}>
						<Button
							onClick={() => setSelcetType(i)}
							className={selectType === i && "activeButton"}
							variant='contained'
							sx={{
								width: "100%",
								p: 1,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							<HouseIcon sx={{ mr: 0.7 }} /> {p?.type}
						</Button>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				Property Photo
			</Typography>
			<Typography
				className='textColor'
				variant='h6'
				component='div'
				gutterBottom>
				At least one photo is required.
			</Typography>
			<Grid container spacing={2}>
				{images?.map((m) => (
					<Grid item md={3} sm={6} xs={12}>
						<Box style={{ width: "150px" }}>
							<img src={m} alt='' style={{ width: "100%" }} />
						</Box>
					</Grid>
				))}
				{insertImage && (
					<Grid item md={3} sm={6} xs={12}>
						{!loading ? (
							<Box>
								<Box style={{ width: "150px" }}>
									<img src={preview} alt='' style={{ width: "100%" }} />
								</Box>
								<Box>
									<DoneIcon
										sx={{ color: "green" }}
										onClick={() => uploadImage(insertImage)}
									/>
									<CloseIcon
										sx={{ color: "red" }}
										onClick={() => setInsertImage(null)}
									/>
								</Box>
							</Box>
						) : (
							<CircularProgress className='textColor' />
						)}
					</Grid>
				)}
				{!insertImage && (
					<Grid item md={3} sm={6} xs={12}>
						<label htmlFor='icon-button-file'>
							<Input
								accept='image/*'
								id='icon-button-file'
								type='file'
								onChange={(e) => setInsertImage(e.target.files[0])}
							/>
							{/* <Button variant='contained' sx={{ p: 3 }}> */}
							<AddAPhotoIcon
								sx={{
									p: 3,
									color: "white",
									borderRadius: 1,
									border: "1px solid",
									cursor: "pointer",
									"&.MuiSvgIcon-root:hover": {
										backgroundColor: "white !important",
										color: "black !important",
									},
								}}
								className='bgColor borderColor'
							/>
							{/* </Button> */}
						</label>
					</Grid>
				)}
			</Grid>

			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				Property Location
			</Typography>
			<Typography
				className='textColor'
				variant='h6'
				component='div'
				gutterBottom>
				Select your property address below.
			</Typography>
			<Grid container spacing={2}>
				<Grid item md={9} xs={12}>
					<TextField
						required
						sx={{ width: "100%" }}
						id='outlined-basic'
						name='address'
						label='Address'
						value={address}
						onChange={(e) => setAddress(e.target.value)}
					/>
				</Grid>
				<Grid item md={3} xs={12}>
					<TextField
						required
						sx={{ width: "100%" }}
						id='outlined-basic'
						name='unit'
						label='Unit'
						value={unit}
						onChange={(e) => setUnit(e.target.value)}
					/>
				</Grid>
			</Grid>
			{enable ? (
				<Button
					onClick={() => {
						nextStep();
						setPage(page + 1);
					}}
					className='sendButton'
					variant='contained'
					sx={{
						width: "250px",
						my: 2,
						px: 3,
						fontWeight: "bold",
						borderRadius: "5px",
						float: "right",
						textAlign: "left",
					}}>
					Next <br /> Property Details <KeyboardTabIcon sx={{ ml: 1 }} />
				</Button>
			) : (
				<Button
					className='sendButton'
					variant='contained'
					sx={{
						width: "250px",
						my: 2,
						px: 3,
						fontWeight: "bold",
						borderRadius: "5px",
						float: "right",
						textAlign: "left",
						backgroundColor: "grey !important",
					}}>
					Next <br /> Property Details <KeyboardTabIcon sx={{ ml: 1 }} />
				</Button>
			)}
		</Container>
	);
};

export default AppartmentType;
