import {
	Card,
	CardContent,
	CardMedia,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AllRents = () => {
	const [rents, setRents] = useState();
	useEffect(() => {
		axios
			.get(`https://dbc.bcard.a2hosted.com/Rents`)
			.then((res) => {
				setRents(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	return (
		<Container>
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h4'
				component='div'>
				Popular Deals
			</Typography>
			<Typography
				className='textColor'
				variant='h6'
				component='div'
				gutterBottom>
				Find your next apartment for rent anywhere in Canada
			</Typography>
			<Grid container spacing={2}>
				{rents?.map((rent) => (
					<Grid item md={6} xs={12}>
						<Link to={`/rents/${rent?._id}`} style={{ textDecoration: "none" }}>
							<Card>
								<CardMedia
									component='img'
									height='200'
									image={rent?.images?.[0]}
									alt={rent?.selectType}
								/>
								<CardContent>
									<Typography variant='h5' className='textColor'>
										{rent?.selectType}
									</Typography>
									<Typography variant='body2' gutterBottom>
										{rent?.address}
									</Typography>
									<Typography variant='body2' color='text.secondary'>
										{rent?.description}
									</Typography>
								</CardContent>
							</Card>
						</Link>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default AllRents;
