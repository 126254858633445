import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppartmentType from "./AppartmentType";
import ContactInfo from "./ContactInfo";
import Descriptions from "./Descriptions";
import PropertyDetails from "./PropertyDetails";

const RentHomeEdit = () => {
	const { id } = useParams();
	const [rent, setRent] = useState();
	useEffect(() => {
		axios
			.get(`https://dbc.bcard.a2hosted.com/RentsById/${id}`)
			.then((res) => {
				setRent(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id]);
	const [page, setPage] = useState(1);
	console.log(rent);
	return (
		<>
			{rent && (
				<>
					{page === 1 && (
						<AppartmentType setPage={setPage} page={page} rentForEdit={rent} />
					)}
					{page === 2 && (
						<PropertyDetails setPage={setPage} page={page} rentForEdit={rent} />
					)}
					{page === 3 && (
						<Descriptions setPage={setPage} page={page} rentForEdit={rent} />
					)}
					{page === 4 && <ContactInfo rentForEdit={rent} />}
				</>
			)}
		</>
	);
};

export default RentHomeEdit;
