import { Box, Typography, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { YouTube } from "@mui/icons-material";
import { useParams } from "react-router-dom";

const Wish = () => {
	const { name } = useParams();
	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: "450px",
				mx: "auto",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
			}}>
			<img
				src='/loading.png'
				alt='Company Logo'
				className='borderColor'
				style={{
					width: "200px",
					height: "200px",
					border: "2px solid",
					borderRadius: "50%",
					boxShadow: "0px 0px 10px 0px #000000",
				}}
			/>
			<Typography
				variant='h3'
				align='center'
				sx={{ mt: 2, mb: 1 }}
				className='textColor'>
				Eid Mubarak!
			</Typography>
			<Typography
				variant='h4'
				align='center'
				sx={{ mb: 2 }}
				className='textColor'>
				{name
					?.split("-")
					.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
					.join(" ")}
			</Typography>
			<Typography variant='body1' align='center'>
				May the spirit of Eid bring you joy, happiness, peace, and prosperity.
				May Allah shower his blessings upon you and your family, and may this
				Eid be a memorable one for you.
			</Typography>
			<Box display='flex' justifyContent='center' mt={2}>
				<IconButton
					sx={{ border: "1px solid", mx: 0.5 }}
					onClick={() =>
						window.open(
							"https://www.facebook.com/profile.php?id=100064033963752",
						)
					}>
					<FacebookIcon />
				</IconButton>
				<IconButton
					sx={{ border: "1px solid", mx: 0.5 }}
					onClick={() => window.open("https://www.youtube.com/samhassoun")}>
					<YouTube />
				</IconButton>
				<IconButton
					sx={{ border: "1px solid", mx: 0.5 }}
					onClick={() =>
						window.open("https://www.instagram.com/sam.hassoun_realtor/")
					}>
					<InstagramIcon />
				</IconButton>
			</Box>
			<Typography variant='body2' mt={2} className='textColor'>
				<a
					className='textColor'
					style={{ textDecoration: "none", fontWeight: "bold" }}
					href='https://bcard.ca/'>
					www.bcard.ca
				</a>{" "}
				||{" "}
				<a
					className='textColor'
					href='tel:226-347-3180'
					style={{ textDecoration: "none", fontWeight: "bold" }}>
					226-347-3180
				</a>
				<br />
				<a
					className='textColor'
					style={{ textDecoration: "none", fontWeight: "bold" }}
					href='http://www.thepropertycousins.net/'>
					www.thepropertycousins.net
				</a>
			</Typography>
			<Typography variant='caption' mt={1}>
				© 2023 The Property Cousins. All Rights Reserved.
			</Typography>
		</Box>
	);
};

export default Wish;
