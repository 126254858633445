import { Container, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwiperCore, { Autoplay, Pagination, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

const Listings = () => {
	const { data: datas } = useAPI();
	const [data, setData] = useState();
	useEffect(() => {
		axios
			.get(`https://dbc.bcard.a2hosted.com/rents`)
			.then((res) => {
				setData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	return (
		<Container sx={{ pt: 5, "& .swiper-slide": { height: "auto" } }}>
			{data?.length ? (
				<>
					<>
						<Typography
							className='textColor'
							sx={{ mb: 0.5, fontWeight: 900 }}
							variant='h4'
							component='div'
							gutterBottom>
							Listings Gallery
							<Divider className={`${datas?.color?.hr}`} />
						</Typography>
						{data?.filter((f) => f?.viewOnGalary === true)?.length > 0 ? (
							<Swiper
								effect={"coverflow"}
								autoHeight={true}
								grabCursor={true}
								loop={true}
								autoplay={{ delay: 4500 }}
								centeredSlides={true}
								slidesPerView={"auto"}
								initialSlide={1}
								coverflowEffect={{
									rotate: 50,
									stretch: 0,
									depth: 100,
									modifier: 1,
									slideShadows: false,
								}}
								pagination={true}>
								{data
									?.filter((f) => f?.viewOnGalary === true)
									?.map((a, key) => (
										<SwiperSlide key={key}>
											<Link
												to={`/rents/${a?._id}`}
												style={{ textDecoration: "none" }}>
												<img src={a?.images?.[0]} alt='' />
											</Link>
											<Typography variant='h5' className='textColor'>
												{a?.selectType}
											</Typography>
											<Typography
												variant='body2'
												className='textColor'
												gutterBottom>
												{a?.address}
											</Typography>
										</SwiperSlide>
									))}
							</Swiper>
						) : (
							<Typography
								className='textColor'
								sx={{ mb: 0.5, fontWeight: 900 }}
								variant='h6'
								component='div'
								gutterBottom>
								No Listings !...
							</Typography>
						)}
					</>
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Listings;
