import {
	Button,
	Container,
	Divider,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import DescriptionIcon from "@mui/icons-material/Description";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";

const Descriptions = ({ page, setPage }) => {
	const features = [
		{
			type: "Commercial Building",
			features: [
				"Commercial Access Gate",
				"Commercial Air Conditioning",
				"Commercial Heating",
				"Commercial Loading Dock",
				"Commercial Security Guard",
			],
		},
		{
			type: "Building Features",
			features: [
				"24h Security",
				"Bbq Area/Courtyard",
				"Bike Racks",
				"Bike Room",
				"Buzzer Entry",
				"Central Air Conditioning",
				"Electric Vehicle Charger",
				"Elevator",
				"Exercise Room",
				"Fitness Area",
				"Garage",
				"Guest Suite",
				"Intercom",
				"Jacuzzi",
				"Laundry Facilities",
				"Movie Room",
				"On-Site Management",
				"On-Site Staff",
				"Parking - Underground",
				"Parking - Visitor",
				"Party Room",
				"Pet Friendly",
				"Pool - Heated",
				"Pool - Rooftop",
				"Professionally Managed",
				"Recreation Room",
				"Recycling",
				"Resident Managers",
				"Rooftop Deck",
				"Rooftop Garden",
				"Rooftop Lounge",
				"Sauna",
				"Secured Access",
				"Security On-Site",
				"Storage Lockers",
				"Swimming Pool",
				"Tennis Court",
				"Theatre Room",
				"Video Surveillance",
			],
		},
		{
			type: "Unit Features",
			features: [
				"Air Conditioning",
				"Alarm System",
				"Backyard",
				"Balcony",
				"Bbq Grill",
				"Blinds",
				"Cable Ready",
				"Dishwasher",
				"Dryer",
				"Ensuite Laundry",
				"Fireplace",
				"Flooring - Carpeted",
				"Flooring - Ceramic",
				"Flooring - Hardwood",
				"Flooring - Laminate",
				"Flooring - Laminate Hardwood",
				"Flooring - Tile",
				"Flooring - Vinyl",
				"Flooring - Wood",
				"Freshly Painted",
				"Furnished",
				"Garburator",
				"Garden",
				"Gas Heating",
				"Granite Countertops",
				"Hot Tub",
				"Individual Thermostats",
				"Island",
				"Microwave",
				"New Appliances",
				"Newly Renovated",
				"Parking",
				"Patio",
				"Pool - Private",
				"Private Entry",
				"Private Yard",
				"Radiant Heat",
				"Security Cameras",
				"Shared Yard",
				"Storage",
				"Terrace",
				"Walk-In Closet",
				"Washer",
				"Wheelchair Access",
				"Window Coverings",
			],
		},
		{
			type: "Nearby",
			features: [
				"24h Emergency",
				"Atm",
				"Bank",
				"Bars",
				"Bike Trails",
				"Bus Stop",
				"Cafe",
				"Convenience Store",
				"Daycare",
				"Dog Park",
				"Gas Station",
				"Grocery Store",
				"Gym",
				"Highway",
				"Hospital",
				"Movie Theater",
				"Parks",
				"Playground",
				"Pool",
				"Public Library",
				"Public Transit",
				"Recreation",
				"Restaurants",
				"Running Path",
				"School",
				"Schools",
				"Shopping",
			],
		},
	];
	const [selectFeaturesCom, setSelectFeaturesCom] = useState([]);
	const [selectFeaturesBuild, setSelectFeaturesBuild] = useState([]);
	const [selectFeaturesUnit, setSelectFeaturesUnit] = useState([]);
	const [selectFeaturesNear, setSelectFeaturesNear] = useState([]);
	const [description, setDescription] = useState();
	const data = {
		selectFeaturesCom,
		selectFeaturesBuild,
		selectFeaturesUnit,
		selectFeaturesNear,
		description,
	};
	const nextStep = () => {
		reactLocalStorage.setObject("dataPage3", { data });
	};
	return (
		<Container sx={{ pt: 5, pb: 2, textAlign: "left" }}>
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<DescriptionIcon sx={{ mr: 0.5 }} /> Description
			</Typography>
			<TextField
				required
				sx={{ width: "100%", mb: 2 }}
				id='"outlined-multiline-flexible'
				label='Description'
				name='Description'
				multiline
				rows={5}
				onChange={(e) => setDescription(e.target.value)}
			/>
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				Features / Amenities
			</Typography>
			<Typography
				className='textColor'
				variant='h6'
				component='div'
				gutterBottom>
				Select the items that apply to your rental
			</Typography>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h5'
				component='div'
				gutterBottom>
				<CorporateFareIcon sx={{ mr: 0.5 }} />
				{features?.[0]?.type}
			</Typography>
			<Grid container spacing={2}>
				{features?.[0]?.features?.map((p, i) => (
					<Grid item md={3} sm={4} xs={12}>
						{selectFeaturesCom?.filter((f) => f === p)?.length === 0 ? (
							<Typography
								className='textColor'
								onClick={() => setSelectFeaturesCom([...selectFeaturesCom, p])}
								variant='p'>
								{p}
							</Typography>
						) : (
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								onClick={() =>
									setSelectFeaturesCom(
										selectFeaturesCom?.filter((f) => f !== p),
									)
								}
								variant='p'>
								{p}
							</Typography>
						)}
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h5'
				component='div'
				gutterBottom>
				<DomainAddIcon sx={{ mr: 0.5 }} />
				{features?.[1]?.type}
			</Typography>
			<Grid container spacing={2}>
				{features?.[1]?.features?.map((p, i) => (
					<Grid item md={3} sm={4} xs={12}>
						{selectFeaturesBuild?.filter((f) => f === p)?.length === 0 ? (
							<Typography
								className='textColor'
								onClick={() =>
									setSelectFeaturesBuild([...selectFeaturesBuild, p])
								}
								variant='p'>
								{p}
							</Typography>
						) : (
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								onClick={() =>
									setSelectFeaturesBuild(
										selectFeaturesBuild?.filter((f) => f !== p),
									)
								}
								variant='p'>
								{p}
							</Typography>
						)}
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h5'
				component='div'
				gutterBottom>
				<RoomPreferencesIcon sx={{ mr: 0.5 }} />
				{features?.[2]?.type}
			</Typography>
			<Grid container spacing={2}>
				{features?.[2]?.features?.map((p, i) => (
					<Grid item md={3} sm={4} xs={12}>
						{selectFeaturesUnit?.filter((f) => f === p)?.length === 0 ? (
							<Typography
								className='textColor'
								onClick={() =>
									setSelectFeaturesUnit([...selectFeaturesUnit, p])
								}
								variant='p'>
								{p}
							</Typography>
						) : (
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								onClick={() =>
									setSelectFeaturesUnit(
										selectFeaturesUnit?.filter((f) => f !== p),
									)
								}
								variant='p'>
								{p}
							</Typography>
						)}
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h5'
				component='div'
				gutterBottom>
				<WhereToVoteIcon sx={{ mr: 0.5 }} />
				{features?.[3]?.type}
			</Typography>
			<Grid container spacing={2}>
				{features?.[3]?.features?.map((p, i) => (
					<Grid item md={3} sm={4} xs={12}>
						{selectFeaturesNear?.filter((f) => f === p)?.length === 0 ? (
							<Typography
								className='textColor'
								onClick={() =>
									setSelectFeaturesNear([...selectFeaturesNear, p])
								}
								variant='p'>
								{p}
							</Typography>
						) : (
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								onClick={() =>
									setSelectFeaturesNear(
										selectFeaturesNear?.filter((f) => f !== p),
									)
								}
								variant='p'>
								{p}
							</Typography>
						)}
					</Grid>
				))}
			</Grid>
			<Button
				onClick={() => {
					nextStep();
					setPage(page + 1);
				}}
				className='sendButton'
				variant='contained'
				sx={{
					width: "250px",
					my: 2,
					px: 3,
					fontWeight: "bold",
					borderRadius: "5px",
					float: "right",
					textAlign: "left",
				}}>
				Next <br /> Lead Contact <KeyboardTabIcon sx={{ ml: 1 }} />
			</Button>
		</Container>
	);
};

export default Descriptions;
