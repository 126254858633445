import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AllMails from "./Pages/Dashboard/AllMails/AllMails";
import AllReviews from "./Pages/Dashboard/AllReviews/AllReviews";
import BannerPhoto from "./Pages/Dashboard/BannerPhoto/BannerPhoto";
import ButtonsLink from "./Pages/Dashboard/ButtonsLink/ButtonsLink";
import ColorChange from "./Pages/Dashboard/ColorChange/ColorChange";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Details from "./Pages/Dashboard/Details/Details";
import Galleryphoto from "./Pages/Dashboard/Galleryphoto/Galleryphoto";
import Headline from "./Pages/Dashboard/Headline/Headline";
import ProfilePhoto from "./Pages/Dashboard/ProfilePhoto/ProfilePhoto";
import HomepageMain from "./Pages/Homepage/HomepageMain";
import Login from "./Pages/Login/Login";
import Bg from "./Pages/Dashboard/Bg/Bg";
import useData from "./useData";
import NotFound from "./Pages/NotFound/NotFound";
import RequireAuth from "./RequireAuth/RequireAuth";
import { CardMedia, Typography } from "@mui/material";
import AddVideo from "./Pages/Dashboard/AddVideo/AddVideo";
import { APIContextProvider } from "./apiContext";
import TeamInvite from "./Pages/Dashboard/TeamInvite/TeamInvite";
import AllUsers from "./Pages/Dashboard/AllUsers/AllUsers";
import MyProfile from "./Pages/Dashboard/MyProfile/MyProfile";
import RequireAuthAdmin from "./RequireAuth/RequireAuthAdmin";
import RentHome from "./Pages/RentHome/RentHome";
import SingleRentView from "./Pages/ViewRents/SingleRentView";
import AllRents from "./Pages/ViewRents/AllRents";
import RentsManage from "./Pages/Dashboard/RentsManage/RentsManage";
import AllRentsManage from "./Pages/Dashboard/AllRentsManage/AllRentsManage";
import { useEffect } from "react";
import RentHomeEdit from "./Pages/RentHomeEdit/RentHomeEdit";
import Wish from "./Wish";
/* const RELOAD_TIMEOUT = 10000;  */ // 10 seconds

function App() {
	const [data] = useData();

	useEffect(() => {
		let timerId;

		// Set a timer to refresh the page if data is not received within 5 seconds
		timerId = setTimeout(() => {
			window.location.reload();
		}, 5000);

		// Clear the timer when data is received
		if (data) {
			clearTimeout(timerId);
		}

		// Cleanup: Clear the timer when the component unmounts
		return () => {
			clearTimeout(timerId);
		};
	}, [data]);

	/* 	useEffect(() => {
		const pingServer = async () => {
			try {
				const response = await fetch(
					`https://dbc.bcard.a2hosted.com/version`,
				);
				if (response.ok) {
					console.log("Server is awake!");
				} else {
					console.warn("Server is not responding as expected.");
				}
			} catch (error) {
				console.error("Error pinging server:", error);
			}
		};

		const pingInterval = setInterval(pingServer, RELOAD_TIMEOUT);

		// Clear the interval when the component unmounts
		return () => clearInterval(pingInterval);
	}, []);
 */
	const url = window.location.pathname;

	const googleTranslateElementInit = () => {
		new window.google.translate.TranslateElement(
			{
				pageLanguage: "en",
				autoDisplay: false,
			},
			"google_translate_element",
		);
	};
	useEffect(() => {
		var addScript = document.createElement("script");
		addScript.setAttribute(
			"src",
			"//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
		);
		document.body.appendChild(addScript);
		window.googleTranslateElementInit = googleTranslateElementInit;
	}, []);
	return (
		<div
			className='App'
			style={{
				backgroundImage: `url(/bg-img.jpg)`,
				position: "relative",
			}}>
			<div
				id='google_translate_element'
				style={{ position: "fixed", top: 0, zIndex: 9999 }}></div>
			{data ? (
				<>
					{document.documentElement.style.setProperty(
						"--color1st",
						`${data?.color?.color1st}`,
					)}
					{document.documentElement.style.setProperty(
						"--hr",
						`${data?.color?.hr}`,
					)}
					{data?._id ? (
						<>
							{data?.status === "Active" ? (
								<APIContextProvider>
									<BrowserRouter>
										<Routes>
											<Route path='*' element={<NotFound />} />
											{url === "/" && (
												<Route path='/' element={<HomepageMain url={`/`} />} />
											)}
											{url.includes("/@") && (
												<Route
													path={`/@${data?.url}`}
													element={<HomepageMain url={`/@${data?.url}`} />}
												/>
											)}

											{!url.includes("/@") ? (
												<Route path='/login' element={<Login />} />
											) : (
												<Route
													path={`/@${data?.url}/login`}
													element={<Login />}
												/>
											)}
											<Route path='/wish/:name' element={<Wish />} />
											{!url.includes("/@") ? (
												<Route
													path='/joinTeam'
													element={
														<RequireAuth userInfo={data}>
															<TeamInvite />
														</RequireAuth>
													}
												/>
											) : (
												<Route
													path={`/@${data?.url}/joinTeam`}
													element={
														<RequireAuth userInfo={data}>
															<TeamInvite />
														</RequireAuth>
													}
												/>
											)}
											{!url.includes("/@") ? (
												<Route path='/rent' element={<RentHome />} />
											) : (
												<Route
													path={`/@${data?.url}/rent`}
													element={<RentHome />}
												/>
											)}
											<Route path='/rents' element={<AllRents />} />
											<Route path='/rents/:id' element={<SingleRentView />} />
											<Route
												exact
												path='/dashboard'
												element={
													<RequireAuth userInfo={data}>
														<Dashboard
															url={window?.location.origin.toString()}
														/>
													</RequireAuth>
												}>
												<Route
													path='/dashboard'
													element={
														<RequireAuth userInfo={data}>
															<MyProfile />
														</RequireAuth>
													}
												/>
												<Route
													path='/dashboard/allusers'
													element={
														<RequireAuthAdmin>
															<AllUsers />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/bannerphoto'
													element={<BannerPhoto />}
												/>
												<Route
													path='/dashboard/RentsManage'
													element={<RentsManage />}
												/>
												<Route
													path='/dashboard/AllRentsManage'
													element={<AllRentsManage />}
												/>
												<Route
													path='/dashboard/AllRentsManage/:id'
													element={<RentHomeEdit />}
												/>
												<Route
													path='/dashboard/profilephoto'
													element={<ProfilePhoto />}
												/>

												<Route
													path='/dashboard/headline'
													element={<Headline />}
												/>

												<Route
													path='/dashboard/buttonslink'
													element={<ButtonsLink />}
												/>

												<Route
													path='/dashboard/colorchange'
													element={<ColorChange />}
												/>
												<Route
													path='/dashboard/details'
													element={<Details />}
												/>

												<Route
													path='/dashboard/galleryphoto'
													element={<Galleryphoto />}
												/>

												<Route path='/dashboard/bg' element={<Bg />} />

												<Route
													path='/dashboard/allreviews'
													element={<AllReviews />}
												/>
												<Route
													path='/dashboard/allemails'
													element={<AllMails />}
												/>

												<Route
													path='/dashboard/managevideo'
													element={<AddVideo />}
												/>
											</Route>
										</Routes>
									</BrowserRouter>
								</APIContextProvider>
							) : (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
										minHeight: "100vh",
									}}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h3'
										component='div'>
										OPPS!
									</Typography>
									<br />
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h5'
										component='div'>
										This card is not available at the moment.
									</Typography>
								</div>
							)}
						</>
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								minHeight: "100vh",
							}}>
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h3'
								component='div'>
								OPPS!
							</Typography>
							<br />
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h5'
								component='div'>
								{data}
							</Typography>
						</div>
					)}
				</>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CardMedia
						image='/loading.png'
						alt='loading logo'
						className='loading-logo'
						sx={{
							width: 170,
							height: 170,
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default App;
