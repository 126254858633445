import { Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const About = () => {
	const { data } = useAPI();
	return (
		<Container sx={{ pt: 5 }}>
			{data?._id ? (
				<>
					{!data?.headline?.aboutUs === "off" ? (
						""
					) : (
						<>
							<Typography
								className='textColor'
								sx={{ mb: 0.5, fontWeight: 900 }}
								variant='h4'
								component='div'
								gutterBottom>
								{data?.headline?.aboutUs}
								<Divider className={`${data?.color?.hr}`} />
							</Typography>
							<Grid container spacing={2}>
								<Grid
									item
									md={9}
									xs={12}
									className='textColor'
									sx={{ mx: "auto" }}>
									<Typography
										variant='h6'
										gutterBottom
										sx={{ whiteSpace: "pre-line" }}>
										{data?.details?.details}
									</Typography>
								</Grid>
								<Grid item md={5} xs={7} sx={{ mx: "auto" }}>
									<img
										style={{ width: "100%" }}
										src={data?.details.imageLink2}
										alt=''
									/>
								</Grid>
							</Grid>
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default About;
