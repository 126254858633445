import { Container, Divider, Typography } from "@mui/material";
import React from "react";
import SwiperCore, { Autoplay, Pagination, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Gallery.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

const Gallery = () => {
	const { data } = useAPI();

	return (
		<Container sx={{ pt: 5, "& .swiper-slide": { height: "auto" } }}>
			{data?._id && data?.headline?.gallery ? (
				<>
					{data?.headline?.gallery === "off" ? (
						""
					) : (
						<>
							<Typography
								className='textColor'
								sx={{ mb: 0.5, fontWeight: 900 }}
								variant='h4'
								component='div'
								gutterBottom>
								{data?.headline?.gallery}
								<Divider className={`${data?.color?.hr}`} />
							</Typography>
							{data?.gallery?.length > 0 ? (
								<Swiper
									effect={"coverflow"}
									autoHeight={true}
									grabCursor={true}
									loop={true}
									autoplay={{ delay: 2500 }}
									centeredSlides={true}
									slidesPerView={"auto"}
									initialSlide={1}
									coverflowEffect={{
										rotate: 50,
										stretch: 0,
										depth: 100,
										modifier: 1,
										slideShadows: false,
									}}
									pagination={true}>
									{data?.gallery?.map((a, key) => (
										<SwiperSlide key={key}>
											<img src={a?.imageLink2} alt='' />
										</SwiperSlide>
									))}
								</Swiper>
							) : (
								<Typography
									className='textColor'
									sx={{ mb: 0.5, fontWeight: 900 }}
									variant='h6'
									component='div'
									gutterBottom>
									No Photos !...
								</Typography>
							)}
						</>
					)}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Gallery;
