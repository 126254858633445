import {
	Backdrop,
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Mail from "./Mail";
import GetAuth from "../../../GetAuth";

const AllMails = () => {
	const [open, setOpen] = React.useState(false);
	const [viewId, setViewId] = useState();
	const handleOpen = (viewMailId) => {
		setViewId(viewMailId);
		setOpen(true);
	};
	const [deleted, setDeleted] = useState(false);
	const [mails, setMails] = useState();
	const [id, setId] = useState();

	const { user, token } = GetAuth();
	useEffect(() => {
		axios
			.get(
				`https://dbc.bcard.a2hosted.com/userbyemail/${user?.email}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res) => {
				setId(res.data?._id);
				setMails(res.data?.emails?.reverse());
			})
			.catch((error) => {
				console.log(error);
			});
	}, [deleted, token, user?.email]);
	const handleDelete = (deletedMailId) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(
						`https://dbc.bcard.a2hosted.com/deleteMail/${id}/${deletedMailId}`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That mail has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;
	return (
		<>
			<Paper
				className='container'
				sx={{ overflow: "auto", maxHeight: "85vh", maxWidth: "90vw", m: 2 }}>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='center' colSpan={4}>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold" }}
									variant='h4'>
									All Mails
								</Typography>
								<Typography className='textColor'>
									Last changed in {mails?.[0]?.submitTime || "N/A"}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Name</TableCell>
							<TableCell align='left'>Rec Time</TableCell>
							<TableCell align='center'>Action</TableCell>
						</TableRow>
					</TableHead>
					{mails?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{mails?.map((mail, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{mail?.userName}</TableCell>
									<TableCell align='left'>{mail?.submitTime}</TableCell>
									{/* 	<TableCell align='left'>
											{mail?.userEmail || "N/A"}
										</TableCell>
										<TableCell align='left'>
											{mail?.phoneNumber || "N/A"}
										</TableCell>
										<TableCell align='left'>{mail?.subject}</TableCell>
										<TableCell align='left'>{mail?.message}</TableCell> */}
									<TableCell align='center'>
										<Button
											onClick={() => handleOpen(mail?.mailId)}
											className='button border'
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												m: 0.5,
											}}
											variant='contained'>
											<RemoveRedEyeIcon />
										</Button>
										<Button
											className='button border'
											onClick={() => handleDelete(mail?.mailId)}
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												m: 0.5,
											}}
											variant='contained'>
											<DeleteIcon />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								{/* 		<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell> */}
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!mails}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && (
				<Mail
					open={open}
					setOpen={setOpen}
					viewId={viewId}
					id={id}
					token={token}
				/>
			)}
		</>
	);
};

export default AllMails;
