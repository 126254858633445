import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Divider,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import Swal from "sweetalert2";

const ContactInfo = ({ rentForEdit }) => {
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		setName(rentForEdit?.name);
		setEmail(rentForEdit?.email);
		setPhone(rentForEdit?.phone);
	}, [rentForEdit]);
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const dataPage1 = reactLocalStorage.getObject("dataPage1");
	const dataPage2 = reactLocalStorage.getObject("dataPage2");
	const dataPage3 = reactLocalStorage.getObject("dataPage3");
	const images = reactLocalStorage.getObject("images");

	return (
		<Container sx={{ pt: 5, pb: 2, textAlign: "left" }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				Contact Details
			</Typography>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Grid container spacing={2}>
				<Grid item md={12} xs={12}>
					<TextField
						required
						sx={{ width: "100%" }}
						id='outlined-basic'
						name='UserName'
						label='Name'
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</Grid>
				<Grid item md={12} xs={12}>
					<TextField
						required
						sx={{ width: "100%" }}
						id='outlined-basic'
						name='UserEmail'
						type='email'
						label='Email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</Grid>
				<Grid item md={12} xs={12}>
					<TextField
						sx={{ width: "100%" }}
						id='outlined-basic'
						name='PhoneNumber'
						type='tel'
						label='Phone Number'
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</Grid>
			</Grid>
			<Button
				type='submit'
				onClick={() => {
					const data = {
						name,
						email,
						phone,
						...dataPage1?.data,
						...dataPage2?.data,
						...dataPage3?.data,
						images,
						viewOnGalary: rentForEdit?.viewOnGalary,
						edited: new Date().toLocaleString(),
						submitTime: rentForEdit?.submitTime,
					};
					setSubmitting(true);
					axios
						.put(
							`https://dbc.bcard.a2hosted.com/Rents/${rentForEdit?._id}`,
							data,
						)
						.then(function (response) {
							setSubmitting(false);
							Swal.fire({
								icon: "success",
								title: "Thank you for your submission!",
								showConfirmButton: true,
								timer: 2500,
							});
							reactLocalStorage.remove("dataPage1");
							reactLocalStorage.remove("dataPage2");
							reactLocalStorage.remove("dataPage3");
							reactLocalStorage.remove("images");
							window.location.href = "/";
						})
						.catch(function (error) {
							console.log(error);
						});
				}}
				className='sendButton'
				variant='contained'
				sx={{
					width: "250px",
					my: 2,
					px: 3,
					fontWeight: "bold",
					borderRadius: "5px",
				}}>
				Submit
			</Button>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ContactInfo;
