import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
	apiKey: "AIzaSyD_FY7LMogCJ9eRziPrsgk5iN0yBkCeZSQ",
	authDomain: "century-21-request-realty.firebaseapp.com",
	projectId: "century-21-request-realty",
	storageBucket: "century-21-request-realty.appspot.com",
	messagingSenderId: "486008457508",
	appId: "1:486008457508:web:27365b66df879a3acc4bb1",
	measurementId: "G-TXB6ETTB39",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
