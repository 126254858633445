import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import PropagateLoader from "react-spinners/PropagateLoader";
/* import Typewriter from "typewriter-effect"; */
import { Wave } from "react-animated-text";
import { useAPI } from "../../../../apiContext";

const Banner = () => {
	const { data } = useAPI();

	return (
		<div>
			{data?._id ? (
				<Card
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						boxShadow: 0,
						position: "relative",
						backgroundColor: "transparent",
					}}>
					<img
						className='logos'
						style={{ width: "100%" }}
						src={data?.details.imageLink2}
						alt=''
					/>
					<Box
						sx={{
							position: "absolute",
							top: { xs: 90, sm: 180, md: 250, lg: 350 },
							zIndex: 1,
						}}>
						<Typography
							className='title'
							sx={{
								fontSize: {
									md: "5.5rem",
									sm: "4.5rem",
									xs: "2.5rem",
									color: data?.headline?.bannerOverLayColor,
								},
								fontWeight: 900,
							}}>
							{data?.headline?.bannerOverLay !== "off"
								? data?.headline?.bannerOverLay || "Buy - Sell - Invest"
								: ""}
						</Typography>
					</Box>
					<CardMedia
						className='bannerPhoto'
						component='img'
						alt=''
						sx={{
							height: "100%",
							position: "relative",
							backgroundSize: "cover",
							objectFit: "fill",
						}}
						image={data?.banner?.imageLink2 || "/sample-banner.jpg"}
					/>
					<Box
						sx={{
							height: { md: 200, xs: 150 },
							width: { md: 200, xs: 150 },
							mt: { md: -11, xs: -7 },
							position: "relative",
						}}>
						<CardMedia
							className='profilePhoto'
							sx={{
								boxShadow: 11,
								borderRadius: "50%",
								width: "100%",
								height: "100%",
							}}
							component='img'
							alt={data?.headline?.title}
							image={data?.profile?.imageLink2 || "/user.jpg"}
						/>
					</Box>
					<CardContent>
						<Typography
							className='textColor title'
							sx={{ fontWeight: 500, fontSize: "2.2rem" }}
							component='div'>
							<Wave
								text={data?.headline?.title}
								effect='jump'
								effectChange={0.5}
								delay={2}
							/>
						</Typography>
						<Typography
							className='textColor'
							sx={{ fontWeight: 700 }}
							variant='h5'
							component='div'>
							{data?.headline?.subtitle || ""}
							{/* <Typewriter
								options={{
									strings: [`${data?.headline?.subtitle || ""}`],
									autoStart: true,
									loop: true,
								}}
							/> */}
						</Typography>
					</CardContent>
				</Card>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</div>
	);
};

export default Banner;
