import {
	Backdrop,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useAPI } from "../../../apiContext";
import Buttons from "../../Homepage/Components/Buttons/Buttons";

const MyProfile = () => {
	const { databyEmail } = useAPI();
	return (
		<Container>
			{databyEmail?._id ? (
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					minHeight='80vh'>
					<Paper elevation={3} sx={{ p: 2, borderRadius: "17px", my: 2 }}>
						<Grid container spacing={2}>
							<Grid item md={5} xs={12} sx={{ mx: "auto" }}>
								<img
									src={databyEmail?.profile?.imageLink2 || "/user.jpg"}
									style={{
										width: "100%",
										height: "100%",
										borderRadius: "15px",
									}}
									alt=''
								/>
							</Grid>
							<Grid item md={7} xs={12} sx={{ mx: "auto", textAlign: "left" }}>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold", fontSize: "1.8rem" }}>
									{databyEmail?.headline?.title || "N/A"}
								</Typography>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
									{databyEmail?.headline?.subtitle || "N/A"}
								</Typography>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold", fontSize: "1rem" }}>
									Joined :- {databyEmail?.joiningTime || "N/A"}
								</Typography>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold" }}
									variant='h6'>
									Role :- {databyEmail?.role || "N/A"}
								</Typography>

								<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
									<LocalPhoneIcon className='textColor' sx={{ mr: 1 }} />
									<Typography className='textColor' sx={{ fontWeight: "bold" }}>
										{databyEmail?.phone || "N/A"}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
									<EmailIcon className='textColor' sx={{ mr: 1 }} />
									<Typography className='textColor' sx={{ fontWeight: "bold" }}>
										{databyEmail?.email || "N/A"}
									</Typography>
								</Box>
								<a
									className='textColor'
									href={`${window.location.origin.toString()}/@${
										databyEmail?.url
									}`}>
									<Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
										<PermIdentityIcon className='textColor' sx={{ mr: 1 }} />
										<Typography
											className='textColor'
											sx={{ fontWeight: "bold" }}>
											{`${window.location.origin.toString()}/@${
												databyEmail?.url
											}`}
										</Typography>
									</Box>
								</a>
								<Box sx={{ mt: 1 }}>
									<Buttons />
								</Box>
							</Grid>
						</Grid>
					</Paper>
				</Box>
			) : (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Container>
	);
};

export default MyProfile;
