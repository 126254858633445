import React, { useContext, useState, createContext } from "react";
import axios from "axios";
import GetAuth from "./GetAuth";

const APIContext = createContext();

export function APIContextProvider({ children }) {
	const { user, token } = GetAuth();
	const [data, setData] = useState();
	React.useEffect(() => {
		const url = window.location.pathname.split("/")[1];
		axios
			.get(`https://dbc.bcard.a2hosted.com/alluser/${url || "owner"}`)
			.then((res) => {
				setData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const [databyEmail, setDatabyEmail] = useState();
	React.useEffect(() => {
		axios
			.get(
				`https://dbc.bcard.a2hosted.com/userbyemail/${user?.email}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res) => {
				setDatabyEmail(res.data);
			})
			.catch((error) => {
				setDatabyEmail({ email: "Not Found" });
			});
	}, [token, user?.email]);
	return (
		<APIContext.Provider
			value={{
				data: data,
				databyEmail: databyEmail,
			}}>
			{children}
		</APIContext.Provider>
	);
}

export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
