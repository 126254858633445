import {
	Backdrop,
	Box,
	Button,
	Container,
	Divider,
	Fab,
	Fade,
	Grid,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import SmsIcon from "@mui/icons-material/Sms";
import InstagramIcon from "@mui/icons-material/Instagram";
import PropagateLoader from "react-spinners/PropagateLoader";
import QRCode from "react-qr-code";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useAPI } from "../../../../apiContext";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import StarRateIcon from "@mui/icons-material/StarRate";
import skype from "../../../../files/skype.svg";
import { Link } from "react-router-dom";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Buttons = () => {
	const { data } = useAPI();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const downloadTxtFile = () => {
		const element = document.createElement("a");
		const file = new Blob(
			[
				`
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${data?.headline?.title}
N;CHARSET=UTF-8:;${data?.headline?.title};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${data?.email}
PHOTO;ENCODING=b;TYPE=X-ICON:IMAGEDATA..
TEL;TYPE=HOME,VOICE:${data?.phone || ""}
TITLE;CHARSET=UTF-8:${data?.headline?.subtitle} 
ORG;CHARSET=UTF-8:Century 21 Request Realty - 606
URL;type=WORK;CHARSET=UTF-8:${window.location.origin.toString()}/@${data?.url}
END:VCARD
`,
			],
			{
				type: "text/x-vcard",
			},
		);
		element.href = URL.createObjectURL(file);
		element.download = `${data?.headline?.title}.vcf`;
		document.body.appendChild(element);
		element.click();
	};

	const [show, setShow] = useState(true);
	const url = window.location.pathname;
	useEffect(() => {
		if (url.includes("/dashboard")) {
			setShow(false);
		}
	}, [url]);

	return (
		<Container>
			{data?._id ? (
				<>
					{show && (
						<Typography
							className='textColor'
							sx={{ fontWeight: "bold" }}
							variant='h6'
							display='block'
							gutterBottom>
							{data?.links?.call}
							<Divider
								className='bgColor'
								sx={{
									width: "18rem",
									border: "1px solid",
									margin: "auto !important",
								}}
							/>
							{data?.headline?.address}
						</Typography>
					)}
					<Grid container spacing={2} className='icons'>
						<>
							{show && (
								<Grid item md={12} xs={12} className='buttonTop'>
									<Link
										to={!url.includes("/@") ? "/rent" : `/@${data?.url}/rent`}
										style={{ textDecoration: "none" }}>
										<Button
											sx={{
												px: 3,
												fontWeight: "bold",
												borderRadius: "25px 0  0 25px",
											}}>
											Sell Your House
										</Button>
									</Link>
									<Link to='/rents' style={{ textDecoration: "none" }}>
										<Button
											sx={{
												px: 3,
												fontWeight: "bold",
												borderRadius: "0 25px 25px 0",
											}}>
											View All Listings
										</Button>
									</Link>
								</Grid>
							)}
						</>
						{data?.links?.website !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.website}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<LanguageIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Website
								</Typography>
							</Grid>
						)}
						{data?.links?.giveaway !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.giveaway}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<StarRateIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Giveaway
								</Typography>
							</Grid>
						)}
						{data?.links?.facebook !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.facebook}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<FacebookOutlinedIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Facebook
								</Typography>
							</Grid>
						)}
						{data?.links?.email !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`mailto:${data?.links?.email}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<EmailOutlinedIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Email
								</Typography>
							</Grid>
						)}
						{data?.links?.whatsapp !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(
											`https://api.whatsapp.com/send?phone=${data?.links?.whatsapp}`,
											"_blank",
										)
									}
									color='primary'
									aria-label='add'>
									<WhatsAppIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Whatsapp
								</Typography>
							</Grid>
						)}
						{data?.links?.youtube !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.youtube}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<SubscriptionsOutlinedIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Youtube
								</Typography>
							</Grid>
						)}
						{data?.links?.call !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() => window.open(`tel:${data?.links?.call}`)}
									color='primary'
									aria-label='add'>
									<LocalPhoneIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									CALL
								</Typography>
							</Grid>
						)}
						{data?.links?.sms !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`sms:${data?.links?.sms}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<SmsIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									TEXT
								</Typography>
							</Grid>
						)}
						{data?.links?.twitter !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.twitter}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<TwitterIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Twitter
								</Typography>
							</Grid>
						)}
						{data?.links?.instagram !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.instagram}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<InstagramIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Instagram
								</Typography>
							</Grid>
						)}
						{data?.links?.linkedin !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.linkedin}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<LinkedInIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									LinkedIn
								</Typography>
							</Grid>
						)}
						{data?.links?.pinterest !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() =>
										window.open(`${data?.links?.pinterest}`, "_blank")
									}
									color='primary'
									aria-label='add'>
									<PinterestIcon />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Pinterest
								</Typography>
							</Grid>
						)}
						{data?.links?.skype !== "off" && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab
									onClick={() => window.open(`${data?.links?.skype}`, "_blank")}
									color='primary'
									aria-label='add'>
									<img src={skype} alt='' />
								</Fab>
								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									Skype
								</Typography>
							</Grid>
						)}

						{show && (
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab onClick={handleOpen} color='primary' aria-label='add'>
									<QrCode2Icon />
								</Fab>

								<Typography
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}
									variant='button'
									display='block'
									gutterBottom>
									QR
								</Typography>
							</Grid>
						)}

						<Modal
							aria-labelledby='transition-modal-title'
							aria-describedby='transition-modal-description'
							open={open}
							onClose={handleClose}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}>
							<Fade in={open}>
								<Box sx={style}>
									<Typography
										className='textColor'
										sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
										id='transition-modal-title'
										variant='h5'>
										Scan Me
									</Typography>
									<QRCode
										fgColor={`${data?.color?.color1st}`}
										value={`${window.location.origin.toString()}/@${data?.url}`}
									/>
								</Box>
							</Fade>
						</Modal>
						{data?.links?.vcf !== "off" && (
							<>
								{show && (
									<Grid className='contactList' item md={12} xs={12}>
										<Fab
											onClick={downloadTxtFile}
											variant='extended'
											sx={{
												px: 4,
												fontWeight: "bold",
											}}>
											<PermContactCalendarIcon sx={{ mr: 1.5 }} />
											Add to contact list
										</Fab>
									</Grid>
								)}
							</>
						)}
					</Grid>
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Buttons;
