import {
	Button,
	Container,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import SettingsInputHdmiIcon from "@mui/icons-material/SettingsInputHdmi";
import CategoryIcon from "@mui/icons-material/Category";
import ConstructionIcon from "@mui/icons-material/Construction";
import PetsIcon from "@mui/icons-material/Pets";
import ChairIcon from "@mui/icons-material/Chair";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ArticleIcon from "@mui/icons-material/Article";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";

const PropertyDetails = ({ page, setPage }) => {
	const utilitiesIncluded = [
		{ type: "Cable" },
		{ type: "Heating" },
		{ type: "Hydro / Electricity" },
		{ type: "Internet / WiFiCable" },
		{ type: "Satellite TV" },
		{ type: "Water" },
	];
	const [utilitiesIncludedArray, setUtilitiesIncludedArray] = useState([]);
	const categories = [
		{ type: "Corporate Housing" },
		{ type: "Student Housing" },
		{ type: "Senior Housing" },
		{ type: "Co-op Housing" },
		{ type: "Sublet" },
		{ type: "Vacation" },
	];
	const [categoriesArray, setCategoriesArray] = useState([]);
	const [yearBuild, setYearBuild] = React.useState("");
	const handleChange = (event) => {
		setYearBuild(event.target.value);
	};
	const petFriendly = [{ type: "Yes" }, { type: "No" }];
	const [petFriendlyArray, setPetFriendlyArray] = useState({ type: "Yes" });
	const furnished = [{ type: "Yes" }, { type: "No" }];
	const [furnishedArray, setFurnishedArray] = useState({ type: "Yes" });
	const shortTerm = [{ type: "Yes" }, { type: "No" }];
	const [shortTermArray, setShortTermArray] = useState({ type: "Yes" });
	const leaseTerm = [
		{ type: "1 Year" },
		{ type: "Monthly" },
		{ type: "Negotiable" },
	];
	const [leaseTermArray, setLeaseTermArray] = useState({ type: "1 Year" });
	const parkingType = [
		{ type: "No Parking" },
		{ type: "Garage" },
		{ type: "Driveway" },
		{ type: "Underg" },
		{ type: "Street" },
	];
	const [parkingTypeArray, setParkingTypeArray] = useState({
		type: "No Parking",
	});
	const parkingCount = [
		{ type: "1" },
		{ type: "2" },
		{ type: "3" },
		{ type: "4" },
		{ type: "5+" },
	];
	const [parkingCountArray, setParkingCountArray] = useState();
	const year = new Date().getFullYear();

	const data = {
		utilitiesIncludedArray,
		categoriesArray,
		yearBuild,
		petFriendly: petFriendlyArray?.type,
		furnished: furnishedArray?.type,
		shortTerm: shortTermArray?.type,
		leaseTerm: leaseTermArray?.type,
		parkingType: parkingTypeArray?.type,
		parkingCount: parkingCountArray?.type,
	};
	const nextStep = () => {
		reactLocalStorage.setObject("dataPage2", { data });
	};
	return (
		<Container sx={{ pt: 5, pb: 2, textAlign: "left" }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				Property Details
			</Typography>
			<Typography
				className='textColor'
				variant='h6'
				component='div'
				gutterBottom>
				Provide important information about your rental. Tip: detailed listings
				tend to get more leads
			</Typography>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<SettingsInputHdmiIcon sx={{ mr: 0.5 }} /> Utilities Included
			</Typography>
			<Grid container spacing={2}>
				{utilitiesIncluded?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12}>
						{utilitiesIncludedArray?.filter((f) => f?.type === p?.type)
							?.length === 0 ? (
							<Button
								onClick={() =>
									setUtilitiesIncludedArray([...utilitiesIncludedArray, p])
								}
								variant='contained'
								sx={{
									width: "100%",
									p: 1,
									fontWeight: "bold",
									borderRadius: "5px",
								}}>
								{p?.type}
							</Button>
						) : (
							<Button
								className={
									utilitiesIncludedArray?.filter((f) => f?.type === p?.type)
										?.length === 1 && "activeButton"
								}
								onClick={() =>
									setUtilitiesIncludedArray(
										utilitiesIncludedArray?.filter((f) => f?.type !== p?.type),
									)
								}
								variant='contained'
								sx={{
									width: "100%",
									p: 3,
									fontWeight: "bold",
									borderRadius: "5px",
								}}>
								{p?.type}
							</Button>
						)}
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<CategoryIcon sx={{ mr: 0.5 }} /> Categories
			</Typography>
			<Grid container spacing={2}>
				{categories?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12}>
						{categoriesArray?.filter((f) => f?.type === p?.type)?.length ===
						0 ? (
							<Button
								onClick={() => setCategoriesArray([...categoriesArray, p])}
								variant='contained'
								sx={{
									width: "100%",
									p: 1,
									fontWeight: "bold",
									borderRadius: "5px",
								}}>
								{p?.type}
							</Button>
						) : (
							<Button
								className={
									categoriesArray?.filter((f) => f?.type === p?.type)
										?.length === 1 && "activeButton"
								}
								onClick={() =>
									setCategoriesArray(
										categoriesArray?.filter((f) => f?.type !== p?.type),
									)
								}
								variant='contained'
								sx={{
									width: "100%",
									p: 3,
									fontWeight: "bold",
									borderRadius: "5px",
								}}>
								{p?.type}
							</Button>
						)}
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<ConstructionIcon sx={{ mr: 0.5 }} /> Year Built
			</Typography>
			<FormControl sx={{ width: "200px" }}>
				<InputLabel id='demo-simple-select-label'> Year Built</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={yearBuild}
					label=' Year Built'
					onChange={handleChange}>
					{Array.from(new Array(200), (val, index) => year - index)?.map(
						(d) => (
							<MenuItem value={d}>{d}</MenuItem>
						),
					)}
				</Select>
			</FormControl>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<PetsIcon sx={{ mr: 0.5 }} /> Pet Friendly?
			</Typography>
			<Grid container spacing={2}>
				{petFriendly?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12} sx={{ mx: "auto" }}>
						<Button
							className={petFriendlyArray?.type === p?.type && "activeButton"}
							onClick={() => setPetFriendlyArray(p)}
							variant='contained'
							sx={{
								width: "100%",
								p: 1,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							{p?.type}
						</Button>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<ChairIcon sx={{ mr: 0.5 }} /> Furnished?
			</Typography>
			<Grid container spacing={2}>
				{furnished?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12} sx={{ mx: "auto" }}>
						<Button
							className={furnishedArray?.type === p?.type && "activeButton"}
							onClick={() => setFurnishedArray(p)}
							variant='contained'
							sx={{
								width: "100%",
								p: 1,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							{p?.type}
						</Button>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<BusinessCenterIcon sx={{ mr: 0.5 }} /> Short Term?
			</Typography>
			<Grid container spacing={2}>
				{shortTerm?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12} sx={{ mx: "auto" }}>
						<Button
							className={shortTermArray?.type === p?.type && "activeButton"}
							onClick={() => setShortTermArray(p)}
							variant='contained'
							sx={{
								width: "100%",
								p: 1,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							{p?.type}
						</Button>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />{" "}
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<ArticleIcon sx={{ mr: 0.5 }} /> Lease Term*
			</Typography>
			<Grid container spacing={2}>
				{leaseTerm?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12} sx={{ mx: "auto" }}>
						<Button
							className={leaseTermArray?.type === p?.type && "activeButton"}
							onClick={() => setLeaseTermArray(p)}
							variant='contained'
							sx={{
								width: "100%",
								p: 1,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							{p?.type}
						</Button>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ my: 2, border: "1px solid" }} className='borderColor' />{" "}
			<Typography
				className='textColor'
				variant='h5'
				component='div'
				sx={{ fontWeight: 900 }}
				gutterBottom>
				<LocalParkingIcon sx={{ mr: 0.5 }} /> Parking Type
			</Typography>
			<Grid container spacing={2}>
				{parkingType?.map((p, i) => (
					<Grid item md={3} sm={6} xs={12}>
						<Button
							className={parkingTypeArray?.type === p?.type && "activeButton"}
							onClick={() => setParkingTypeArray(p)}
							variant='contained'
							sx={{
								width: "100%",
								p: 1,
								fontWeight: "bold",
								borderRadius: "5px",
							}}>
							{p?.type}
						</Button>
					</Grid>
				))}
			</Grid>
			{parkingTypeArray?.type !== "No Parking" && (
				<>
					{" "}
					<Divider
						sx={{ my: 2, border: "1px solid" }}
						className='borderColor'
					/>
					<Typography
						className='textColor'
						variant='h5'
						component='div'
						sx={{ fontWeight: 900 }}
						gutterBottom>
						<DirectionsCarFilledIcon sx={{ mr: 0.5 }} /> Parking Spots
					</Typography>
					<Grid container spacing={2}>
						{parkingCount?.map((p, i) => (
							<Grid item md={2} sm={3} xs={4}>
								<Button
									className={
										parkingCountArray?.type === p?.type && "activeButton"
									}
									onClick={() => setParkingCountArray(p)}
									variant='contained'
									sx={{
										width: "100%",
										p: 3,
										fontWeight: "bold",
										borderRadius: "5px",
									}}>
									{p?.type}
								</Button>
							</Grid>
						))}
					</Grid>
				</>
			)}
			<Button
				onClick={() => {
					nextStep();
					setPage(page + 1);
				}}
				className='sendButton'
				variant='contained'
				sx={{
					width: "250px",
					my: 2,
					px: 3,
					fontWeight: "bold",
					borderRadius: "5px",
					float: "right",
					textAlign: "left",
				}}>
				Next <br /> Floor Plans <KeyboardTabIcon sx={{ ml: 1 }} />
			</Button>
		</Container>
	);
};

export default PropertyDetails;
