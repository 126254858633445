import {
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	NativeSelect,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import { Box } from "@mui/system";
import GetAuth from "../../../GetAuth";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

const AllRentsManage = () => {
	const { token } = GetAuth();
	const [deleted, setDeleted] = useState(false);
	const [data, setData] = useState();
	useEffect(() => {
		axios
			.get(`https://dbc.bcard.a2hosted.com/rents`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				setData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [deleted, token]);

	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(`https://dbc.bcard.a2hosted.com/Rents/${id}`)
					.then(function (response) {
						Swal.fire(
							"Deleted!",
							"That rent request has been deleted.",
							"success",
						);
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	const [search, setSearch] = React.useState("");
	const handleChange = (event) => {
		setSearch(event.target.value);
	};
	const [newList, setNewList] = React.useState([]);
	useEffect(() => {
		const searchs = data
			?.reverse()
			.filter(
				(user) =>
					user?.name?.toLowerCase().includes(search.toLowerCase()) ||
					user?.email?.toLowerCase().includes(search.toLowerCase()) ||
					user?.phone?.toLowerCase().includes(search.toLowerCase()),
			);
		setNewList(searchs);
	}, [search, data]);

	const [perpage, setPerpage] = React.useState(10);
	const [newArray, setNewArray] = React.useState();
	const [page, setPage] = React.useState(1);
	const handleChanges = (event, value) => {
		setPage(value);
	};
	useEffect(() => {
		setNewArray(newList?.slice((page - 1) * perpage, page * perpage));
	}, [newList, page, perpage]);

	return (
		<>
			<Box sx={{ height: "155px", mx: 2 }}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h4'>
					All Rent Requests
				</Typography>
				<Typography className='textColor'>
					Last Added in {data?.[data?.length - 1]?.submitTime || "N/A"}
				</Typography>
				<TextField
					size='small'
					sx={{ width: "100%", maxWidth: "400px" }}
					id='outlined-name'
					placeholder='Search by Name / Email / Phone '
					value={search}
					onChange={handleChange}
				/>
				<Box
					className='pagination'
					sx={{ display: "flex", justifyContent: "center", my: 1 }}>
					<FormControl>
						<NativeSelect
							defaultValue={perpage}
							onChange={(e) => setPerpage(e.target.value)}>
							<option value={10}>10</option>
							<option value={15}>15</option>
							<option value={20}>20</option>
							<option value={40}>40</option>
							<option value={50}>50</option>
						</NativeSelect>
					</FormControl>
					<Pagination
						sx={{
							display: "flex",
							justifyContent: "center !important",
						}}
						count={Math.ceil(newList?.length / perpage)}
						page={page}
						onChange={handleChanges}
						variant='outlined'
						shape='rounded'
					/>
				</Box>
			</Box>
			<Paper
				className='container'
				sx={{
					overflow: "auto",
					maxHeight: "calc(90vh - 155px)",
					maxWidth: "90vw",
					mx: 2,
				}}>
				<Table size='small'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Type</TableCell>
							<TableCell align='left'>Submitted By</TableCell>
							<TableCell align='left'>Email</TableCell>
							<TableCell align='left'>Phone</TableCell>
							<TableCell align='left'>Action</TableCell>
							<TableCell align='left'>View On Gallery?</TableCell>
						</TableRow>
					</TableHead>
					{data?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{newArray?.map((user, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{user?.selectType}</TableCell>
									<TableCell align='left'>{user?.name}</TableCell>
									<TableCell align='left'>{user?.email}</TableCell>
									<TableCell align='left'>{user?.phone}</TableCell>
									<TableCell align='left'>
										<Box sx={{ display: "flex" }} className='online'>
											<Link
												to={`/dashboard/AllRentsManage/${user?._id}`}
												style={{ textDecoration: "none" }}>
												<Button
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<EditIcon />
												</Button>
											</Link>
											<Button
												onClick={() =>
													window
														.open(
															`${window.location.origin}/rents/${user?._id}`,
															"_blank",
														)
														.focus()
												}
												className='button border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<LanguageIcon />
											</Button>

											<Button
												className='button border'
												onClick={() => handleDelete(user?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</Box>
									</TableCell>
									<TableCell align='left'>
										<Box sx={{ display: "flex" }} className='online'>
											{user?.viewOnGalary === false ? (
												<Button
													onClick={() => {
														setDeleted(true);
														axios
															.put(
																`https://dbc.bcard.a2hosted.com/Rents/${user?._id}`,
																{
																	viewOnGalary: true,
																},
															)
															.then(function (response) {
																Swal.fire(
																	"success!",
																	"That rent is visible on gallery.",
																	"success",
																);
																setDeleted(false);
															})
															.catch(function (error) {
																console.log(error);
															});
													}}
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<DoneIcon />
												</Button>
											) : (
												<Button
													onClick={() => {
														setDeleted(true);
														axios
															.put(
																`https://dbc.bcard.a2hosted.com/Rents/${user?._id}`,
																{
																	viewOnGalary: false,
																},
															)
															.then(function (response) {
																Swal.fire(
																	"success!",
																	"That rent is hidden from gallery.",
																	"success",
																);
																setDeleted(false);
															})
															.catch(function (error) {
																console.log(error);
															});
													}}
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<CloseIcon />
												</Button>
											)}
										</Box>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!data || deleted}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</>
	);
};

export default AllRentsManage;
