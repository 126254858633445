import {
	Box,
	Container,
	Divider,
	Grid,
	Pagination,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const Video = () => {
	const { data } = useAPI();
	const [page, setPage] = useState(0);
	return (
		<Container sx={{ pt: 5 }}>
			{data?._id ? (
				<>
					{data?.headline?.video === "off" ? (
						""
					) : (
						<>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography
									className='textColor'
									sx={{ fontWeight: 900 }}
									variant='h4'
									component='div'
									gutterBottom>
									{data?.headline?.video}
									<Divider className={`${data?.color?.hr}`} />
								</Typography>
							</Box>
							{data?.videos?.length > 0 ? (
								<Grid container spacing={2}>
									<Grid item md={9} xs={12} sx={{ mx: "auto" }}>
										<ReactPlayer
											className='borderColor react-player'
											width='100%'
											url={data?.videos[page]?.videoLink}
											controls
										/>
									</Grid>
									<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
										<Typography
											className='textColor'
											sx={{ fontWeight: 900 }}
											variant='h5'
											component='div'>
											{data?.videos[page]?.videoTitle}
										</Typography>
									</Grid>
									<Grid
										className='pagination'
										item
										md={9}
										xs={12}
										sx={{ mx: "auto" }}>
										<Pagination
											sx={{
												display: "flex",
												justifyContent: "center !important",
											}}
											count={data?.videos?.length}
											onChange={(e, value) => setPage(value - 1)}
											variant='outlined'
											shape='rounded'
										/>
									</Grid>
								</Grid>
							) : (
								<Typography
									className='textColor'
									sx={{ mb: 0.5, fontWeight: 900 }}
									variant='h6'
									component='div'
									gutterBottom>
									No Videos !...
								</Typography>
							)}
						</>
					)}
					{/* {data?.links?.worth !== "off" && (
						<Fab
							onClick={() => window.open(`${data?.links?.worth}`, "_blank")}
							variant='extended'
							sx={{
								px: 4,
								mt: 1,
								fontWeight: "bold",
							}}>
							What's Your Home Worth?
						</Fab>
					)} */}
				</>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default Video;
