import React, { useState } from "react";
import AppartmentType from "./AppartmentType";
import ContactInfo from "./ContactInfo";
import Descriptions from "./Descriptions";
import PropertyDetails from "./PropertyDetails";

const RentHome = () => {
	const [page, setPage] = useState(1);
	return (
		<>
			{page === 1 && <AppartmentType setPage={setPage} page={page} />}
			{page === 2 && <PropertyDetails setPage={setPage} page={page} />}
			{page === 3 && <Descriptions setPage={setPage} page={page} />}
			{page === 4 && <ContactInfo />}
		</>
	);
};

export default RentHome;
