import {
	Backdrop,
	Card,
	CircularProgress,
	Container,
	Fade,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const Mail = ({ open, setOpen, viewId, id, token }) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		axios
			.get(
				`https://dbc.bcard.a2hosted.com/alluser/emails/${id}/${viewId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res) => {
				setData(res.data[0]);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id, token, viewId]);

	return (
		<Container>
			{data?.userName ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style}>
							<Box>
								<Card
									className='borderColor'
									sx={{
										p: 2,
										my: 1,
										minWidth: "300px",
										display: "flex",
										flexDirection: "column",
										alignContent: "center",
										overflow: "visible",
										border: "2px solid ",
										textAlign: "left",
										borderRadius: 3,
									}}>
									<CancelIcon
										onClick={() => setOpen(false)}
										className='textColor'
										sx={{
											position: "fixed",
											top: "13px",
											right: "5px",
											cursor: "pointer",
										}}
									/>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'
										sx={{ mt: 1 }}>
										Hello,
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'
										sx={{ mt: 1 }}>
										<b>Name : </b>
										{data?.userName}
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'
										sx={{ mt: 1 }}>
										<b>Email : </b>
										{data?.userEmail}
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'
										sx={{ mt: 1 }}>
										<b>Phone : </b>
										{data?.phoneNumber || "N/A"}
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'
										sx={{ mt: 1 }}>
										<b>Rec Time : </b>
										{data?.submitTime}
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'
										sx={{ mt: 1 }}>
										<b>Subject : </b>
										{data?.subject}
									</Typography>
									<Typography
										gutterBottom
										variant='h6'
										component='div'
										className='textColor'
										sx={{ mt: 1 }}>
										<b>Message : </b>
										{data?.message}
									</Typography>
								</Card>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Container>
	);
};

export default Mail;
