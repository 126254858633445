import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useEffect } from "react";
import { useState } from "react";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useAPI } from "../../../apiContext";
import GetAuth from "../../../GetAuth";

const SubmitInfo = ({ setState }) => {
	const { user, loading } = GetAuth();
	const [url, setUrl] = React.useState("");
	const [users, setUsers] = useState();
	const { data } = useAPI();
	useEffect(() => {
		axios
			.get(`https://dbc.bcard.a2hosted.com/allusers`)
			.then((res) => {
				setUsers(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	const [search, setSearch] = useState("");
	const handleInputChange = (e) => {
		const dm = e.target.value;
		const str = dm.toString();
		const debug = users.filter((user) => user?.url.toLowerCase() === str);
		setSearch(
			debug?.length === 0
				? false
				: "This username already exists, Please try a different one",
		);
		setUrl(str?.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""));
	};
	console.log(url);
	const [submitting, setSubmitting] = useState(false);

	const { handleSubmit, register } = useForm();
	const onSubmit = ({ name, phone }) => {
		setSubmitting(true);
		axios
			.post(`https://dbc.bcard.a2hosted.com/makeNewUser`, {
				url,
				email: user?.email,
				name: name,
				phone: phone,
				recuretedBy: data?.url,
				time: new Date().toLocaleString(),
			})
			.then(function (response) {
				setSubmitting(false);
				setState(true);
				Swal.fire({
					icon: "success",
					title: "Your Successfully Requested For Joining The Team",
					showConfirmButton: true,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Box style={{ marginTop: 3, textAlign: "center" }}>
			<Box>
				<Avatar sx={{ mx: "auto", my: 1 }} className='bgColor'>
					<PersonAddAltIcon />
				</Avatar>
				<Typography
					component='h1'
					variant='h5'
					className='textColor'
					sx={{ fontWeight: "bold" }}>
					Request To Join Team
				</Typography>
			</Box>
			<form
				onSubmit={handleSubmit(onSubmit)}
				method='post'
				style={{ display: "flex", flexDirection: "column" }}>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
						<Grid container>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								<TextField
									margin='normal'
									required
									fullWidth
									id='name'
									label='Your Name'
									name='name'
									autoComplete='Name'
									autoFocus
									sx={{ maxWidth: "100%" }}
									{...register("name", { required: true })}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								<TextField
									margin='normal'
									required
									fullWidth
									id='email'
									label='Email Address'
									name='email'
									autoComplete='email'
									autoFocus
									value={user?.email}
									sx={{ maxWidth: "100%" }}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								<TextField
									margin='normal'
									required
									fullWidth
									id='phone'
									label='Your Phone Number'
									name='phone'
									autoComplete='phone'
									autoFocus
									sx={{ maxWidth: "100%" }}
									{...register("phone", { required: true })}
								/>
							</Grid>
							<Grid
								item
								md={12}
								xs={12}
								sx={{ mx: "auto", my: 2 }}
								className='username'>
								<input
									className='borderColor textColor'
									placeholder='Select a username'
									style={{
										placeholderColor: "red !important",
										padding: "21px 15px",
										border: "1px solid ",
										borderRadius: "4px",
										width: "100%",
										boxSizing: " border-box",
									}}
									type='text'
									onChange={(e) => handleInputChange(e)}
								/>
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								{search === false ? (
									<p></p>
								) : (
									<Typography sx={{ color: "red !important" }}>
										{search}
									</Typography>
								)}
							</Grid>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								{search === false ? (
									<Button
										type='submit'
										className='confirm'
										fullWidth
										variant='contained'>
										Request Now
									</Button>
								) : (
									<Button
										disabled
										fullWidth
										variant='contained'
										sx={{
											backgroundColor: "gray !important",
										}}>
										Request Now
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading || submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default SubmitInfo;
