import {
	Backdrop,
	Button,
	CircularProgress,
	Container,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, EffectCoverflow } from "swiper";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

const SingleRentView = () => {
	const { id } = useParams();
	const [rents, setRents] = useState();
	useEffect(() => {
		axios
			.get(`https://dbc.bcard.a2hosted.com/RentsById/${id}`)
			.then((res) => {
				setRents(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id]);
	console.log(rents);
	const { register, handleSubmit, reset } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const onSubmit = ({ userName, userEmail, phoneNumber, message }) => {
		const datas = {
			mailId: `mail${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			userEmail,
			phoneNumber,
			message,
			submitTime: new Date().toLocaleString(),
			rentReqFor: id,
		};
		setSubmitting(true);

		axios
			.post(`https://dbc.bcard.a2hosted.com/RentRequsets`, datas)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your Mail Sent Successfully",
					showConfirmButton: true,
					timer: 1500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Container sx={{ textAlign: "left" }} className='rents'>
			{rents?._id ? (
				<Box sx={{ maxWidth: "1090px" }}>
					<Box
						sx={{
							position: "sticky",
							top: 0,
							borderBottom: "2px solid ",
							mb: 2,
							backgroundColor: "white",
							zIndex: 999,
						}}>
						<Box sx={{ display: "flex" }}>
							<Typography
								className='textColor'
								sx={{ fontWeight: 900 }}
								variant='h5'
								component='div'>
								{rents?.selectType} -
							</Typography>

							<Typography
								className='textColor'
								sx={{ fontWeight: 900 }}
								variant='h5'
								component='div'>
								&nbsp;
								{rents?.unit} Unit
							</Typography>
						</Box>
						<Typography
							className='textColor'
							sx={{ fontWeight: 900 }}
							variant='h6'
							component='div'
							gutterBottom>
							{rents?.submitTime || new Date().toLocaleString()}
						</Typography>
						<Typography
							className='textColor'
							sx={{ fontWeight: 900 }}
							variant='p'
							component='div'
							gutterBottom>
							{rents?.address}
						</Typography>
					</Box>
					<Grid container spacing={2}>
						<Grid item md={7} sm={7} xs={12}>
							<Box>
								{rents?.images?.length > 0 ? (
									<Swiper
										effect={"coverflow"}
										autoHeight={true}
										grabCursor={true}
										loop={true}
										autoplay={{ delay: 2500 }}
										centeredSlides={true}
										slidesPerView={"auto"}
										initialSlide={1}
										coverflowEffect={{
											rotate: 50,
											stretch: 0,
											depth: 100,
											modifier: 1,
											slideShadows: false,
										}}
										pagination={true}>
										{rents?.images?.map((a, key) => (
											<SwiperSlide key={key}>
												<img src={a} alt='' />
											</SwiperSlide>
										))}
									</Swiper>
								) : (
									<Typography
										className='textColor'
										sx={{ mb: 0.5, fontWeight: 900 }}
										variant='h6'
										component='div'
										gutterBottom>
										No Photos !...
									</Typography>
								)}
								<Box sx={{ textAlign: "center", my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Description
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'
										gutterBottom>
										{rents?.description}
									</Typography>
								</Box>
								<Typography
									className='textColor'
									sx={{ fontWeight: 900, textAlign: "center", mt: 2, mb: 3 }}
									variant='h5'
									component='div'
									gutterBottom>
									All Features
								</Typography>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Utilities Included
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'
										gutterBottom>
										{rents?.utilitiesIncludedArray?.map((a, key) => (
											<Typography
												className='textColor'
												sx={{ fontWeight: 900 }}
												variant='p'
												component='div'>
												{key + 1} : {a?.type}
											</Typography>
										))}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Categories
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'
										gutterBottom>
										{rents?.categoriesArray?.map((a, key) => (
											<Typography
												className='textColor'
												sx={{ fontWeight: 900 }}
												variant='p'
												component='div'>
												{key + 1} : {a?.type}
											</Typography>
										))}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Year Built
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'>
										{rents?.yearBuild}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Pet Friendly?
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'>
										{rents?.petFriendly}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Furnished?
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'>
										{rents?.furnished}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Short Term?
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'>
										{rents?.shortTerm}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Lease Term*
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'>
										{rents?.leaseTerm}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Parking Type
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='p'
										component='div'>
										{rents?.parkingType}
										{rents?.parkingType === "No Parking"
											? 0
											: ` (${rents?.parkingCount})`}
									</Typography>
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Commercial Building
									</Typography>
									{rents?.selectFeaturesCom?.map((a, key) => (
										<Typography
											className='textColor'
											sx={{ fontWeight: 900 }}
											variant='p'
											component='div'>
											{key + 1} : {a}
										</Typography>
									))}
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Building Features
									</Typography>
									{rents?.selectFeaturesBuild?.map((a, key) => (
										<Typography
											className='textColor'
											sx={{ fontWeight: 900 }}
											variant='p'
											component='div'>
											{key + 1} : {a}
										</Typography>
									))}
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Unit Features
									</Typography>
									{rents?.selectFeaturesUnit?.map((a, key) => (
										<Typography
											className='textColor'
											sx={{ fontWeight: 900 }}
											variant='p'
											component='div'>
											{key + 1} : {a}
										</Typography>
									))}
								</Box>
								<Box sx={{ my: 3 }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h5'
										component='div'
										gutterBottom>
										Nearby
									</Typography>
									{rents?.selectFeaturesNear?.map((a, key) => (
										<Typography
											className='textColor'
											sx={{ fontWeight: 900 }}
											variant='p'
											component='div'>
											{key + 1} : {a}
										</Typography>
									))}
								</Box>
							</Box>
						</Grid>
						<Grid item md={5} sm={5} xs={12}>
							<Box sx={{ position: "sticky", top: 100 }}>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h4'
										component='div'>
										Contact Property
									</Typography>
									<Typography
										className='textColor'
										sx={{ fontWeight: 900 }}
										variant='h6'
										component='div'
										gutterBottom>
										The Property Cousins
									</Typography>
								</Box>
								<Grid container spacing={2}>
									<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
										<form onSubmit={handleSubmit(onSubmit)} method='post'>
											<Grid container spacing={2}>
												<Grid item md={12} xs={12}>
													<TextField
														size='small'
														fullWidth
														required
														id='outlined-basic'
														name='UserName'
														type={"text"}
														label='Name'
														{...register("userName", { required: true })}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														required
														size='small'
														fullWidth
														id='outlined-basic'
														name='UserEmail'
														type='email'
														label='Email'
														{...register("userEmail", { required: true })}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<TextField
														required
														size='small'
														fullWidth
														id='outlined-basic'
														name='PhoneNumber'
														type='tel'
														label='Phone Number'
														{...register("phoneNumber", { required: true })}
													/>
												</Grid>{" "}
												<Grid item md={12} xs={12}>
													<TextField
														required
														size='small'
														fullWidth
														id='"outlined-multiline-flexible'
														label='Message'
														name='Message'
														multiline
														rows={5}
														defaultValue="Hi, I'd like to schedule a visit to your rental property I found on bcard.ca. Please let me know when the best date and time would be. Thank you!"
														{...register("message", { required: true })}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<Button
														className='sendButton'
														type='submit'
														variant='contained'
														sx={{
															width: "100%",
															mb: 2,
															px: 3,
															fontWeight: "bold",
															borderRadius: "25px",
														}}>
														Submit
													</Button>
												</Grid>
											</Grid>
										</form>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default SingleRentView;
